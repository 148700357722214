import "./style.scss";
import { isDefined } from "@clipboard-health/util-ts";
import { IonButton } from "@ionic/react";
import { CbhFeatureFlag, FEATURE_FLAG_DEFAULT_VALUES, useCbhFlag } from "@src/appV2/FeatureFlags";
import { useGetShiftCancelFeedback } from "@src/appV2/Shifts/MyShiftUnavailable";
import { CancelShiftKey } from "@src/appV2/Shifts/MyShiftUnavailable/constants";
import { type CancelShiftFeedback } from "@src/appV2/Shifts/MyShiftUnavailable/useGetShiftCancelFeedback";
import { Shift } from "@src/appV2/Shifts/Shift/types";
import { differenceInSeconds } from "date-fns";
import { useEffect, useState } from "react";

import { AttendancePolicyCancelActionSheet } from "./attendancePolicyCancelActionSheet";

/**
 * Formats a given number of seconds to a string in the format of minutes and seconds.
 * For example, if 62 is passed, it will return 1:02.
 * @param seconds The number of seconds to format.
 * @returns A string in the format of minutes and seconds.
 */
function formatToMinutesAndSeconds(seconds: number): string {
  const minutes = Math.floor(seconds / 60);
  const remainingSeconds = seconds % 60;
  return `${minutes}:${remainingSeconds.toString().padStart(2, "0")}`;
}

function getCancelButtonText(params: {
  isSentHomeFlowV2Enabled: boolean;
  cancellationSecondsLeft: number;
  shiftCancelFeedback: CancelShiftFeedback;
}): string {
  const { isSentHomeFlowV2Enabled, cancellationSecondsLeft, shiftCancelFeedback } = params;
  if (cancellationSecondsLeft > 0) {
    return `Undo booking without penalty ${formatToMinutesAndSeconds(cancellationSecondsLeft)}`;
  }
  return !isSentHomeFlowV2Enabled || shiftCancelFeedback.cancelShiftKey === CancelShiftKey.CANCEL
    ? "Cancel"
    : "Cancel / Sent Home";
}

function useCancellationTimer(
  cancellationWithoutPenaltyThresholdTime: Date | undefined,
  setCancellationSecondsLeft: (seconds: number) => void
) {
  useEffect(() => {
    if (!isDefined(cancellationWithoutPenaltyThresholdTime)) {
      // If worker clocks-in when timer is running, we need to set this to 0 to not show the countdown anymore
      setCancellationSecondsLeft(0);
      return;
    }

    const updateCancelButtonText = () => {
      const secondsLeft = differenceInSeconds(cancellationWithoutPenaltyThresholdTime, new Date());
      setCancellationSecondsLeft(secondsLeft);
      if (secondsLeft <= 0) {
        clearInterval(cancelTimerInterval);
      }
    };

    const cancelTimerInterval = setInterval(updateCancelButtonText, 1000);
    updateCancelButtonText(); // Run immediately on effect execution
    return () => clearInterval(cancelTimerInterval);
  }, [cancellationWithoutPenaltyThresholdTime, setCancellationSecondsLeft]);
}

/**
 * Once the user clicks on "Undo Booking" button (which sets clientCancellation),
 * we allow them to cancel without penalty if they confirm within X seconds.
 * The cancellation logic (attendance score, urgent shifts) is in the backend, but this hook helps show the appropriate messages.
 */
function useIsCancellationButtonClickWithinUndoBookingBuffer(params: {
  clientCancellation: {
    time: Date;
    cancelButtonClickedBeforeTimerExpired: boolean;
  };
  undoBookingBufferAfterButtonClickInSeconds: number;
}) {
  const { clientCancellation, undoBookingBufferAfterButtonClickInSeconds } = params;
  const [isCancellationButtonClickWithinBuffer, setIsCancellationButtonClickWithinBuffer] =
    useState<boolean>(false);

  useEffect(() => {
    if (
      !clientCancellation.cancelButtonClickedBeforeTimerExpired ||
      undoBookingBufferAfterButtonClickInSeconds <= 0
    ) {
      return;
    }

    setIsCancellationButtonClickWithinBuffer(true);
    const timeout = setTimeout(() => {
      setIsCancellationButtonClickWithinBuffer(false);
    }, undoBookingBufferAfterButtonClickInSeconds * 1000);

    return () => clearTimeout(timeout);
  }, [
    // We need the entire `clientCancellation` as a dependency (not just `cancelButtonClickedBeforeTimerExpired`)
    // because we want the hook to run (and recalculate the buffer) if the `time` changes
    clientCancellation,
    undoBookingBufferAfterButtonClickInSeconds,
  ]);

  return isCancellationButtonClickWithinBuffer;
}

export const AttendancePolicyCancelShiftButton: React.VFC<{
  shift: Shift;
  fill?: "clear" | "outline";
}> = ({ shift, fill = "outline" }) => {
  const [showCancelActionSheet, setShowCancelActionSheet] = useState(false);
  const [cancellationSecondsLeft, setCancellationSecondsLeft] = useState<number>(0);
  const [clientCancellation, setClientCancellation] = useState<{
    time: Date;
    cancelButtonClickedBeforeTimerExpired: boolean;
  }>({ time: new Date(), cancelButtonClickedBeforeTimerExpired: false });

  const { isSentHomeFlowV2Enabled } = useCbhFlag(CbhFeatureFlag.SENT_HOME_REQUEST_CONFIG, {
    defaultValue: FEATURE_FLAG_DEFAULT_VALUES[CbhFeatureFlag.SENT_HOME_REQUEST_CONFIG],
  });
  const undoBookingBufferAfterButtonClickInSeconds = useCbhFlag(
    CbhFeatureFlag.UNDO_BOOKING_BUFFER_AFTER_BUTTON_CLICK_IN_SECONDS,
    {
      defaultValue:
        FEATURE_FLAG_DEFAULT_VALUES[
          CbhFeatureFlag.UNDO_BOOKING_BUFFER_AFTER_BUTTON_CLICK_IN_SECONDS
        ],
    }
  );
  const cancellationWithoutPenaltyThresholdTime = isDefined(
    shift.cancellationWithoutPenaltyThresholdTime
  )
    ? new Date(shift.cancellationWithoutPenaltyThresholdTime)
    : undefined;
  const shiftCancelFeedback = useGetShiftCancelFeedback(shift);

  useCancellationTimer(cancellationWithoutPenaltyThresholdTime, setCancellationSecondsLeft);
  const isCancellationButtonClickWithinUndoBookingBuffer =
    useIsCancellationButtonClickWithinUndoBookingBuffer({
      clientCancellation,
      undoBookingBufferAfterButtonClickInSeconds,
    });

  return (
    <>
      <IonButton
        data-testid="attendance-policy-cancellation-button"
        size="large"
        expand="block"
        fill={fill}
        shape="round"
        className="attendance-policy-cancel-button"
        onClick={() => {
          setShowCancelActionSheet(true);
          setClientCancellation({
            time: new Date(),
            cancelButtonClickedBeforeTimerExpired: cancellationSecondsLeft > 0,
          });
        }}
      >
        {getCancelButtonText({
          isSentHomeFlowV2Enabled,
          cancellationSecondsLeft,
          shiftCancelFeedback,
        })}
      </IonButton>
      <AttendancePolicyCancelActionSheet
        showCancelActionSheet={showCancelActionSheet}
        setShowCancelActionSheet={setShowCancelActionSheet}
        shift={shift}
        canCancelWithoutPenalty={
          // Clients can cancel without penalty if
          // 1. Countdown timer has not run out (cancellationSecondsLeft > 0) OR
          // 2. The button was clicked before the timer ran out AND
          //    not more than 15 seconds have elapsed (isCancellationButtonClickWithinUndoBookingBuffer)
          cancellationSecondsLeft > 0 || isCancellationButtonClickWithinUndoBookingBuffer
        }
        isSentHomeEnabled={
          shiftCancelFeedback.cancelShiftKey === CancelShiftKey.CANCEL_OR_SENT_HOME
        }
        clientCancellationTime={clientCancellation.time}
      />
    </>
  );
};
